
import { getcookie,getQueryString,delQueStr,isAL,isWeiXin,isWeiXinWork,setcookie } from '@/components/tools';
import http from '@/components/http';
import nativeApp from '@/mains/nativeApp/main';


var domain =['https://bx.wts9999.net','https://bx.wts999.com','https://bx-web.wts999.com','https://bx-bj.wts999.com',,'https://bx-bj.wts999.com'];
var base = ['wts9999.net','wts999.com','wts999.com','wts999.com','wts999.com']
var initParams = {
    // domain: domain,//域名-全面
    historyLocal: ['WTS_USER', 'WTS_USER_2'],//历史缓存名称
    // base: base,//域名-一级
    wxscope: "snsapi_userinfo",
    zfbcode: "auth_base",
    autoLogin:  '/uc/ucUser/autoLogin', //获取登录数据
    //微信相关
    wxoauthappid: '/uc/login/wxoauthappid',
    wxmplogin: '/uc/login/wxmplogin',
    wxmploginHtml: '/m/wxmplogin.html',
    //支付宝相关
    getAliOauthUrl: '/uc/login/getThirdOauthUrl',
    aliOauthBaseInfo: '/uc/login/thirdOauthBaseInfo',
}
var blankOrigin='www.fxyf99.com,bp.lzyunying.com,fqyf.wts9999.net';
var initCallback;
var envirenment=1;
function init(options, callBack){
    envirenment=options.env;
    initParams.domain=domain[options.env];
    initParams.base=base[options.env];
    initCallback=callBack;
    var login = getQueryString('login');
    var sessionlogin = sessionStorage.getItem('login');
    // b端域名
    if(blankOrigin.indexOf(location.host)>-1){
        initCallback(false)
        return;
    }
    // 携带参数不登录
    if (login && login == 2) {
        sessionStorage.setItem('login', login);
        initCallback(false)
        return;
    } else if (sessionlogin && sessionlogin == 2) {
        initCallback(false)
        return;
    }
    var token = getcookie('WTS_TOKEN_A');
    //app环境下不走token获取用户信息流程
    if (token && navigator.userAgent.indexOf("wtsApp") < 0) {
        getUserInfo();
    } else {
        toChannel();
    }
}

/**
 *获取用户信息
 *
 * @returns
 */
function getUserInfo () {
    var loginDatas = JSON.parse(sessionStorage.getItem("WTS_USER_3"));
    if (loginDatas) {
        if (checkUserServerCode(loginDatas))return;
        return initCallback(loginDatas)
    }
    http.ajax({
        type: "POST",
        url: initParams.autoLogin,
        async: false,
        dataType: 'json',
        success: function (result, er) {
            if (result.httpCode == 500) {
                initCallback(false)
                return;
            }
            if (result.httpCode == 403) {
                clearUser()
                return toChannel()
            }
            if (checkUserServerCode(result.data)) return;
            reportUp(result.data)
            if (result.data) {
                sessionStorage.setItem("WTS_USER_3",JSON.stringify(result.data));
            }
            return initCallback(result.data);
        },
        error: function (err){
            console.log(initParams.autoLogin+'err',err); 
        }
    });
}

/**
     * 判断是哪个渠道登录-微信，支付宝，h5
     */
    function toChannel() {
        if(isWeiXinWork()){
            h5mplogin();
            return;
        }else if (isWeiXin()) {
            //微信
            wxmplogin();
        } else if (isAL()) {
            //支付宝
            ALmplogin();
        } else if(navigator.userAgent.indexOf("wtsApp") > -1){
            appmpLogin();
        }else {
            h5mplogin();
        }
    }
    function wxmplogin() {
        var code = getQueryString('code');
        var state = getQueryString('state');
        var key = "oauth.wx.mp.appid";
        if (!code) {
            http.ajax({
                type: 'get',
                url: initParams.wxoauthappid,
                async: false,
                cache: false,
                data: {
                    key: key
                },
                dataType: 'json',
                success: function (result) {
                    //将页面跳转至微信授权页
                    if (result != null && result.httpCode == 200 && result.data != "") {
                        var redirectUrl = wechat_redirect_url(result);//获取授权地址
                        location.href = redirectUrl;
                    } else {
                        alert('获取appid失败 \n ' + result);
                    }
                }
            });
        } else {
            http.ajax({
                type: 'get',
                url: initParams.wxmplogin,
                async: false,
                cache: false,
                data: {
                    code: code,
                    state: state
                },
                dataType: 'json',
                success: function (result) {
                    if(result.msg == "重复CODE请求" || result.httpCode == 409){
                        var tempHref = location.href;
                        tempHref = delQueStr(tempHref, 'code');
                        tempHref = delQueStr(tempHref, 'state');
                        return window.location.replace(tempHref);
                    }
                    if (result != null && result.httpCode == 200 && result.data != "") {
                        console.log(result.data);
                        getUserInfo();
                        //如果静默授权登录失败,则换成显示授权重新登录一次
                    } else if (result.httpCode == 303 && initParams.wxscope == "snsapi_base") {
                        initParams.wxscope = "snsapi_userinfo";
                        http.ajax({
                            type: 'get',
                            url: initParams.wxoauthappid,
                            async: false,
                            cache: false,
                            data: {
                                key: key
                            },
                            dataType: 'json',
                            success: function (result) {
                                //将页面跳转至微信授权页
                                if (result != null && result.httpCode == 200 && result.data != "") {
                                    var redirectUrl = wechat_redirect_url(result);//获取授权地址
                                    location.href = redirectUrl;
                                } else {
                                    alert('获取appid失败 \n ' + JSON.stringify(result));
                                }
                            },
                            
                        });
                        //location.href=url;
                    } else {
                        alert('微信身份识别失败 \n ' + JSON.stringify(result));
                    }
                }
            });
        }
    }
    function ALmplogin() {
        var Request= getQueryStringByVue(); //实例化
        var auth_code =Request.auth_code || "";
        var app_id = Request.app_id;
        var source = Request.source;
        var scope = Request.scope;
        if (auth_code == "") {
            var redirectUrl = al_redirect_url();//获取授权地址
            location.href = redirectUrl;
        } else {
            http.ajax({
                type: 'POST',
                url: initParams.aliOauthBaseInfo,
                async: false,
                contentType: "application/json",
                data: JSON.stringify({
                    "appId": app_id,
                    "source": source,
                    "scope": scope,
                    "authCode": auth_code.split("#")[0],
                    "serverCode": 'alp'
                }),
                dataType: 'json',
                success: function (result) {
                    var url;
                    if(result.msg == "请求参数出错" || result.httpCode == 400){
                        url = location.href;
                        url = delQueStr(url, "auth_code");
                        url = delQueStr(url, "app_id");
                        url = delQueStr(url, "source");
                        url = delQueStr(url, "userOutputs");
                        url = delQueStr(url, "alipay_token");
                        return window.location.replace(url);
                    }
                    if (result != null && result.httpCode == 200  && result.data) {
                        getUserInfo();
                    }else if(result.httpCode == 303 && initParams.zfbcode == "auth_base"){
                        initParams.zfbcode = "auth_user";
                        var redirectUrl = al_redirect_url();//获取授权地址   
                        location.href = redirectUrl;
                    }else {
                        alert('支付宝身份识别失败 \n ' + result);
                    }
                }
            });
        }
    }

    /**获取app原生返回的用户信息 */
    function appmpLogin(){
         var loginDatas = sessionStorage.getItem("WTS_USER_3");
        if (loginDatas) {
            return initCallback(JSON.parse(loginDatas))
        }
        nativeApp.general.deviceready(function(){
            nativeApp.user.getUserInfo(function (res) {
                console.log(res);
                sessionStorage.setItem("WTS_USER_3", res);
                if(res) res = JSON.parse(res);
                var token = res.token;
                if(token){
                   setcookie('WTS_TOKEN_A',token,{path: "/", domain: initParams.base, secure: true})
                }
                return initCallback(res);
            }, function () {
                return initCallback(false);
            });
        })
    }

    function h5mplogin() {
        var loginDatas = JSON.parse(sessionStorage.getItem("WTS_USER_3"));
        if (loginDatas) {
            if (checkUserServerCode(loginDatas))return;
            return initCallback(loginDatas)
        }
        return initCallback(false)
    }

    //获取地址栏参数
    function getQueryStringByVue() {
        var obj = {};
        var name,value; 
        var str=location.href; //取得整个地址栏
        var num=str.indexOf("?") 
        str=str.substr(num+1); //取得所有参数   stringvar.substr(start [, length ]
        var arr=str.split("&"); //各个参数放到数组里
        for(var i=0;i < arr.length;i++){ 
            num=arr[i].indexOf("="); 
            if(num>0){ 
            name=arr[i].substring(0,num);
            value=arr[i].substr(num+1);
            obj[name]=value;
            } 
        }
        return obj; 
    }

    //清除本地cookie和缓存的用户信息
    function clearUser() {
        setcookie('WTS_TOKEN_A', '', {
            path: "/",
            domain: initParams.base,
            secure: true
        });
        var historys = initParams.historyLocal;
        for (var k in historys) {
            localStorage.clear(historys[k]);
        }
        sessionStorage.clear();
    }

    //判断用户信息环境
    function checkUserServerCode(userDta) {
        var result=false;
        if (isAL() && userDta.serverCode != "alp") {
            result= true;
        }
        if (isWeiXin() && userDta.serverCode != "wx") {
            result= true;
        }
        if(result){
            clearUser()
            toChannel()
        }
        return result;
    }

    //获取微信授权地址
    function wechat_redirect_url(result) {
        var wxappid = result.data.appid; //appid
        var domain = result.data.domain; //安全域名
        var fromurl = '';
        var tempHref = location.href;
        tempHref = delQueStr(tempHref, 'code');
        tempHref = delQueStr(tempHref, 'state');
        //如果当前访问域名不在安全域名内,则跳转到个人中心的登录跳转页
        fromurl = document.domain == domain ? encodeURIComponent(tempHref) : encodeURIComponent(initParams.domain+initParams.wxmploginHtml + "?jumpToUrl=" + tempHref);
        var url = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + wxappid + '&redirect_uri=' + fromurl + '&response_type=code&scope=' + initParams.wxscope + '&state=' + initParams.wxscope + '#wechat_redirect';
        return url;
    }
    //获支付宝授权地址
    function al_redirect_url() {
        var tempHref = location.href;
        
        //如果当前访问域名不在安全域名内,则跳转到个人中心的登录跳转页
        var fromurl = encodeURIComponent(initParams.domain+initParams.wxmploginHtml);
        if(location.href.indexOf(initParams.wxmploginHtml)<0){
            setcookie('sessionHash',encodeURIComponent(tempHref),{
                path: "/",
                domain: initParams.base,
                secure: true,
                expires:1000 * 60 * 10
            })
        }
        var url = initParams.getAliOauthUrl + "?serverCode=alp&scope="+initParams.zfbcode + "&url=" + fromurl;
        return url;
    }

    //判断是否存才hash
    function hasHash() {
        if (window.location.hash && window.location.hash.length > 0) {
            if(isAL() && window.location.hash.split("?").length>1){
                return "";
            }
            return window.location.hash;
        }
        return "";
    }
    function reportUp(data){
        var userType = 1,userSign = null;
        var user = data||sessionStorage.WTS_USER_3||{};
        var actionType=2;
        var actionContent="平常登录"
        if(user&&user.mobile1){
            userSign = user.mobile1;
            userType = 2;
        }
        if(user.ifRegister){
            actionType=1;
            actionContent="首次登录"
        }
        var base=envirenment==1?"https://bmf.wts999.com/br/up":"https://bmf.wts9999.net/br/up"
        http.ajax({
            type: 'POST',
            url: base,
            contentType: "application/json",
            data: JSON.stringify({
                userSign:userSign||'',
                userType:userType||'',
                actionType:actionType||'',
                actionId:'',
                actionContent:actionContent||''
            }),
            dataType: 'json',
            success: function (result) {
            },
        });
    }

    export default {
        init:init
    }