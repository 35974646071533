/**
 * app
 * 核心全局app方法
 */
const app = {
    user: {
        //获取app相关用户信息
        getUserInfo: function (success, fail) {
            navigator.general.getUserInfo(success, fail);
        },
        //通知app退出登录
        loginOut: function (success, fail) {
            navigator.general.logout(success, fail);
        }

    },

    weixin: {
        /**初始化分享*
        * params:{
               router (链接)
               路由：
               wts://share?type=1(分享网页链接)&link=&title=&content=&logo=
               wts://share?type=2(分享图片)&link=(图片链接)
               wts://share?type=3(分享小程序)&link=&title=&content=&logo=&userName=&path=
           }
        * **/
        initShare: function (router, success, fail) {
            navigator.general.rightIcon(router, success, fail);
        },
        /**微信支付*
         * params:{
                appid (微信appid)
                partnerid (微信商户id)
                prepayid (订单id)
                package (财付通文档填写的数据和签名微信开放平台文档对数据做的签名)
                timestamp (时间戳)
                sign (签名)
                noncestr (随机串)
            }
         * **/
        wxPay: function (params, success, fail) {
            navigator.wechat.pay(params, success, fail);
        },
        /**打开微信小程序*
         * params:{
                userName (小程序appid)
                path (小程序页面路径)
                miniProgramType (小程序类型 0:正式版  1:开发版  2:体验版)
            }
         * **/
        openMiniProgram: function (params, success, fail) {
            navigator.wechat.openMiniProgram(params, success, fail);
        }
    },

    aliPay: {
        /**主动拉起支付宝支付*
        * params:{
               orderInfo (后台请求获取支付宝支付相关的字符串信息)
           }
        * **/
        aliPay: function (params, success, fail) {
            navigator.alipay.pay(params, success, fail);
        }
    },

    general: {
        /**打开app内置页面*
         * params:{
                url (页面路径)
                title (页面标题  可选参数)
            }
         * **/
        router: function (params, success, fail) {
            navigator.general.router(params, success, fail);
        },

        /**凤栖云服粉丝管理发送计划书*
         * params:{
                url (页面路径)
                title (页面标题  可选参数)
                desc
                img
            }
         * **/
        fansChatSendLink: function (params, success, fail) {
            navigator.general.fansChatSendLink(params, success, fail);
        },

        /**关闭当前webview页面*
         * **/
        closeWebView: function () {
            navigator.general.close();
        },

        /**pdf预览*
         * params:{
                url (pdf链接)
                title (页面标题  可选参数)
            }
         * **/
        readPdf: function (params) {
            navigator.general.readPdf(params);
        },
        /**下载*
         * params:{
                url (图片链接)
                type (文件类型:image  audio video)
            }
         * **/
        download: function (params, success, fail) {
            navigator.general.download(params, success, fail);
        },

        /**打开视频播放*
        * params:{
               url (视频链接)
           }
        * **/
        playVideo: function (params, success, fail) {
            navigator.general.playVideo(params, success, fail);
        },

        //视频上传人脸识别
        uploadRecognition: function (params, success, fail) {
            navigator.general.uploadRecognition(params, success, fail);
        },


        /**设备初始化*
         *callback
         * **/
        deviceready: function (callback) {
            document.addEventListener('deviceready', function () {
                callback();
            }, false);
        },

        //获取设备信息
        getDeviceInfo: function (success, fail) {
            navigator.general.getDeviceInfo(success, fail);
        },
        //隐藏右上角图标
        hideRightIcon: function (success, fail) {
            navigator.general.hideRightIcon(success, fail);
        },

        // 拉起app人脸识别
        faceAuthentication: function (params, success, fail) {
            navigator.general.faceAuthentication(params, success, fail);
        },

        //获取大决策登录token
        getTempToken: function (success, fail) {
            navigator.general.getTempToken(success, fail);
        },

        /**设置大决策右上角文案*
        * params:{
               type 1:文字/2:图片
               router:点击携带数据(用户回调给前端)
               content:1:显示的文字/2:图片地址
           }
        * **/
        titleMenu: function (params, success) {
            navigator.general.titleMenu(params, success);
        },

        /**大决策支付*
       * params:{
              type 1 大咖币  2.vip
              payInfo:支付数据
              productId:{
                  type:1 短线擒牛  2.股市赢家
                  actId：//活动id
                  body：""
              }
              payprice:支付金额
          }
       * **/
        rechargeDialog: function (params, success, fail) {
            navigator.general.rechargeDialog(params, success, fail);
        },


        /**大决策http请求相关*
       * params:[
              "url",
              "POST",
              {header},
              {body},
              {query}
          ]
       * **/
        httpRequest: function (params, success, fail) {
            navigator.general.httpRequest(params, success, fail);
        }

    }
}
export default app;