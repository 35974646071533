

/**
 * 获取浏览器基本信息
 */
function getBrowser() {
    if(!navigator){
        return {}
    }
    var u = navigator.userAgent;
    return {
        trident: u.indexOf('Trident') > -1, //IE内核
        presto: u.indexOf('Presto') > -1, //opera内核
        webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
        gecko: u.indexOf('Firefox') > -1, //火狐内核Gecko
        mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
        ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios
        android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //android
        iPhone: u.indexOf('iPhone') > -1, //iPhone
        iPad: u.indexOf('iPad') > -1, //iPad
        webApp: u.indexOf('Safari') > -1, //Safari
        user: u,
    };
}

/**
 * 获取地址栏参数
 */
export function getQueryString(name, type) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    var search = window.location.search;
    if (type) {
        search = decodeURI(search);
    }
    var r = search.substr(1).match(reg);
    if (r != null) return unescape(r[2]);
    return null;
} 
/**
 * 是否保险app环境内
 */
export function isWtsApp(u) {
    //微信
    var ua = u?u.toLowerCase():getBrowser().user.toLowerCase();
    return ua.match(/wtsapp/i) == 'wtsapp' ? true : false;
}

/**
 * 是否B端环境
 */
export function ischannelB() {
    var blankOrigin='www.fxyf99.com,fqyf.wts9999.net';
    if(blankOrigin.indexOf(location.host)>-1){
        return true;
    }else{
        return false;
    }
}

/**
 * 是否新版app环境内
 */
export function isWtsNativeApp() {
    var ua = getBrowser().user.toLowerCase();
    return ua.match(/wtsApp/i) == 'wtsapp' || ua.match(/com.wts.jjr/i) == 'com.wts.jjr' ? true : false;
}
/**
 * 是否凤栖云服app
 */
export function isFQYFApp(u) {
    var ua = u?u.toLowerCase():getBrowser().user.toLowerCase();
    return ua.match(/com.wts.fqyf/i) == 'com.wts.fqyf' ? true : false;
}
/**
 * 是否姜子牙app
 */
export function isJzyApp() {
      var ua = getBrowser().user.toLowerCase()
      return ua.match(/com.wts.dldyr/i) == "com.wts.dldyr" ? true : false
}
/**
 * 是否梧桐树合伙人app
 */
export function isWtshhrApp() {
      var ua = getBrowser().user.toLowerCase()
      return ua.match(/com.wts.dldyr/i) == "com.wts.dldyr" ? true : false
}
/**
 * 是否千策云智投app
 */
export function isQcyztApp() {
      var ua = getBrowser().user.toLowerCase()
      return ua.match(/com.djc.qcyzt/i) == "com.djc.qcyzt" ? true : false
}
/**
 * 是否ios环境
 */
export function isIOS() {
    var ua = getBrowser().ios || getBrowser().iPad || getBrowser().iPhone
    return ua;
}
/**
 * 是否移动端
 */
export function isMobile() {
    var ua = getBrowser().mobile;
    return ua;
}

/**
 * 是否微信端
 */
export function isWeiXin(u) {
    var ua = u?u.toLowerCase():getBrowser().user.toLowerCase();
    return ua.match(/MicroMessenger/i) == 'micromessenger' && (ua.match(/wxwork/i) != 'wxwork') ? true : false;
}
/**
 * 是否企业微信端
 */
export function isWeiXinWork(u) {
    var ua = u?u.toLowerCase():getBrowser().user.toLowerCase();
    if( (ua.match(/MicroMessenger/i) == 'micromessenger') && (ua.match(/wxwork/i) == 'wxwork') ){
        return true;
    }
    return false;
}

/**
 * 是否支付宝环境
 */
export function isAL(u) {
    var ua = u?u.toLowerCase():getBrowser().user.toLowerCase();
    return ua.match(/Alipay/i) == 'alipay' ? true : false;
}

/**
 * 是否百度app环境
 */
export function isBaidu(u) {
    var ua = u?u.toLowerCase():getBrowser().user.toLowerCase();
    return ua.match(/Baiduboxapp/i) == 'baiduboxapp' ? true : false;
}

/**
 * 是否今日头条环境
 */
export function isTouTiao(u) {
    var ua = u?u.toLowerCase():getBrowser().user.toLowerCase();
    return ua.match(/Newsarticle/i) == 'newsarticle' ? true : false;
}

/**
 * 是否抖音环境
 */
export function isDouYin(u) {
    var ua = u?u.toLowerCase():getBrowser().user.toLowerCase();
    return ua.match(/Aweme/i) == 'aweme' ? true : false;
}

/**
 * 是否钉钉环境
 */
export function isDD(u) {
    var ua = u?u.toLowerCase():getBrowser().user.toLowerCase();
    return ua.indexOf('dingtalk') >= 0 ? true : false;
}
/**
 * 是否iphonex环境
 */
export function isIphonex() {
    var u = navigator.userAgent;
    var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    if (isIOS) {
        if (screen.height == 812 && screen.width == 375) {
            return true;
        }
        else {
            return false;
        }
    }
}

/**
 * 获取安卓版本号
 */
export function getAndroidVersion() {
    var user = getBrowser().user;
    var index = user.indexOf('Android');
    if (index > 0) {
        return parseFloat(user.slice(index + 8));
    } else {
        return null;
    }
}
/**
 * 删除地址栏参数
 * url-地址
 * ref-删除参数名称
 */
export function delQueStr(url, ref) {
    var str = "";
    if (url.indexOf('?') != -1) {
        str = url.substr(url.indexOf('?') + 1);
    } else {
        return url;
    }
    var arr = "";
    var returnurl = "";
    if (str.indexOf('&') != -1) {
        arr = str.split('&');
        for (var i in arr) {
            if (arr[i].split('=')[0] != ref) {
                returnurl = returnurl + arr[i].split('=')[0] + "=" + arr[i].split('=')[1] + "&";
            }
        }
        return url.substr(0, url.indexOf('?')) + "?" + returnurl.substr(0, returnurl.length - 1) + hasHash();
    } else {
        arr = str.split('=');
        if (arr[0] == ref) {
            return url.substr(0, url.indexOf('?')) + hasHash();
        } else {
            return url;
        }
    }
}

/**
 * 获取当前hash值
 */
function hasHash() {
    if (window.location.hash && window.location.hash.length > 0) {
        if (isAL() && window.location.hash.split("?").length > 1) {
            return "";
        }
        return window.location.hash;
    }
    return "";
}

/**
 * 获取cookie
 */
export function getcookie(name) {
    var strcookie = document.cookie;
    var arrcookie = strcookie.split('; ');
    for (var i = 0; i < arrcookie.length; i++) {
        var arr = arrcookie[i].split('=');
        if (arr[0] == name) return decodeURIComponent(arr[1]);
    }
    return '';
}
/**
 * 设置cookie
 */
export function setcookie(key, val, params) {
    var param = '';
    if(params){
        for (var k in params) {
            param += '' + k + '=' + params[k] + ';';
        }
    }
    if(!params || !params.expires){
        var d = new Date();
        d.setTime(d.getTime() + (1000 * 60 * 60 * 24 * 30));
        d=d.toGMTString();
        param += 'expires==' + d + ';';
    }
    document.cookie = key + "=" + escape(val) + ";"+param;
}

/**
 * 设置本地储存
 */
export function setLocal(name, value) {
    var curTime = new Date().getTime();
    localStorage.setItem(
        name,
        JSON.stringify({
            data: value,
            time: curTime,
        })
    );
}
/**
 * 获取本地储存
 */
export function getLocal(name, exp) {
    var data = localStorage.getItem(name);
    if(!data)return null;
    var dataObj = JSON.parse(data);
    if (!exp) {
        var exp = 1000 * 60 * 60 * 24 * 3;
    }
    if (dataObj && new Date().getTime() - dataObj.time > exp) {
        localStorage.removeItem(name);
        return null;
    } else {
        var dataObjDatatoJson = !dataObj ? null : dataObj.data;
        return dataObjDatatoJson;
    }
}

/**
 * 获取身份证对应的性别和年龄
 */
export function getDateSex(num) {
    var UUserCard = num;
    var returns = {
        date:'',
        age: '',
        sex: ''
    };
    //获取出生日期
    var date = UUserCard.substring(6, 10) + "-" + UUserCard.substring(10, 12) + "-" + UUserCard.substring(12, 14);
    returns.date=date;
    //获取性别
    if (parseInt(UUserCard.substr(16, 1)) % 2 == 1) {
        returns.sex = 1;
    } else {
        returns.sex = 2;
    }
    //获取年龄
    var myDate = new Date();
    var month = myDate.getMonth() + 1;
    var day = myDate.getDate();
    var age = myDate.getFullYear() - UUserCard.substring(6, 10) - 1;
    if (UUserCard.substring(10, 12) < month || UUserCard.substring(10, 12) == month && UUserCard.substring(12, 14) <= day) {
        age++;
    }
    returns.age = age;
    return returns;
}

/**
 * 截取字符串  根据字符是否为圆角字符来记数
 */
export function cutString(content, length) {
    var _ret = getCutValue(content, length);
    var _cutFlag = _ret.cutflag;
    var _cutStringn = _ret.cutstring;
    if (_cutFlag) {
        return _cutStringn + '...';
    } else {
        return _cutStringn;
    }
}

/**
 * 获取原字符串长度
 */
function getCutValue(pStr, pLen) {
    var _strLen = pStr.length;
    var _cutString;
    // 默认情况下，返回的字符串是原字符串的一部分
    var _cutFlag = true;
    var _lenCount = 0;
    var _ret = false;

    if (_strLen <= pLen / 2) {
        _cutString = pStr;
        _ret = true;
    }
    if (!_ret) {
        for (var i = 0; i < _strLen; i++) {
            if (isFull(pStr.charAt(i))) {
                _lenCount += 2;
            } else {
                _lenCount += 1;
            }
            if (_lenCount > pLen) {
                _cutString = pStr.substring(0, i);
                _ret = true;
                break;
            } else if (_lenCount == pLen) {
                _cutString = pStr.substring(0, i + 1);
                _ret = true;
                break;
            }
        }
    }
    if (!_ret) {
        _cutString = pStr;
        _ret = true;
    }

    if (_cutString.length == _strLen) {
        _cutFlag = false;
    }
    return {
        cutstring: _cutString,
        cutflag: _cutFlag
    };
}

/**
 * 是否全角文字
 */
function isFull(pChar) {
    if (pChar.charCodeAt(0) > 128) {
        return true;
    } else {
        return false;
    }
}

/**
 * 生产随机id
 */
export function randomRangeId(num=8) {
    var returnStr = "",
        charStr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (var i = 0; i < num; i++) {
        var index = Math.round(Math.random() * (charStr.length - 1));
        returnStr += charStr.substring(index, index + 1);
    }
    return returnStr;
}

/**
 * 生产随机id
 */
export function randomRangeIdBig(num=8) {
    var returnStr = "",
        charStr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    for (var i = 0; i < num; i++) {
        var index = Math.round(Math.random() * (charStr.length - 1));
        returnStr += charStr.substring(index, index + 1);
    }
    return returnStr;
}

/**
 * 过滤空格
 */
export function trim(val) {
    return val.replace(/(^\s*)|(\s*$)/g, '');
}

/**
 * 判断是数组
 */
export function isArray(o) {
    return Object.prototype.toString.call(o) == '[object Array]';
}

/**
 * 新增css
 */
export function addCss(css) {
    var D = document, _s = D.createElement('style');
    _s.setAttribute("type", "text/css");
    _s.styleSheet && (_s.styleSheet.cssText = css) ||
        _s.appendChild(D.createTextNode(css));
    D.getElementsByTagName('head')[0].appendChild(_s);
}

/**
 *动态加载js
 */
export function loadScript(url, callback) {
     // 传入url , 和要执行的回调函数
     const script = document.createElement('script');
     script.type = 'text/javascript';
     // 创建一个script标签
     if (script.readyState) {
        // 做兼容
        script.onreadystatechange = function() {
            // readyState变化触发
            if (script.readyState === 'complete' || script.readyState === 'loaded') {
                if(callback)callback();  // 加载完执行回调
            }
        }
    } else {
        script.onload = function() {
            if(callback)callback();  // 加载完执行回调
        }
    }
    var href=url.indexOf('?')>-1?url+="&v="+randomRangeId(6):url+="?v="+randomRangeId(6)
    script.src = href;
    document.head.appendChild(script);  // 插入head中
}

/**
 *动态加载js代码到body后
 */
export function loadJscodeBody(text, callback) {
     // 传入url , 和要执行的回调函数
     const script = document.createElement('script');
     script.type = 'text/javascript';
     // 创建一个script标签
     if (script.readyState) {
        // 做兼容
        script.onreadystatechange = function() {
            // readyState变化触发
            if (script.readyState === 'complete' || script.readyState === 'loaded') {
                if(callback)callback();  // 加载完执行回调
            }
        }
    } else {
        script.onload = function() {
            if(callback)callback();  // 加载完执行回调
        }
    }
    script.innerHTML = text;
    document.body.appendChild(script);  // 插入body中
}
